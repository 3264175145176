.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.second-component-hub {
  width: 78%;
  margin-left: 20%;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media (max-width:996px) {
  .second-component-hub {
    width: 95% !important;
    margin-left: 2% !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.markdown-editor {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.editor-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.editor,
.preview {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.editor {
  font-family: monospace;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.5;
}

.preview {
  border-left: 1px solid #ccc;
}

/* Table styling */
.preview table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}

.preview th,
.preview td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.preview th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Code block styling */
.preview pre {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.preview code {
  font-family: monospace;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.toolbar-group {
  display: flex;
  gap: 5px;
}

.toolbar-button {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.toolbar-button:hover {
  background-color: #e0e0e0;
}

.toolbar-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.toolbar-button.save {
  background-color: #4CAF50;
  color: white;
}

.toolbar-button.save:hover {
  background-color: #45a049;
}

.toolbar-button.cancel {
  background-color: #f44336;
  color: white;
}

.toolbar-button.cancel:hover {
  background-color: #da190b;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 3px;
  overflow: hidden;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}

textarea.form-control {
  min-height: 100px;
  resize: vertical;
}

.second-component {
  width: 80% !important;
  margin-left: 20% !important;
}

.second-component-nav-close {
  width: 90% !important;
  margin-left: 10% !important;
}

.navbar {
  background-color: var(--bg-color-2);
}

.logo img {
  width: 100%;
  height: auto;
}

/* For tablets and above */
@media (min-width: 768px) {
  .nav-custom-style {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 1rem;
  }

  .left-nav-fixed {
    position: fixed;
    height: 100vh;
    width: 250px;
  }

  .content-with-sidebar {
    margin-left: 250px;
  }
}

/* For larger desktops */
@media (min-width: 992px) {
  .left-nav-fixed {
    width: 300px;
  }

  .content-with-sidebar {
    margin-left: 300px;
  }
}

/* Mobile styles */
@media (max-width: 767.98px) {
  .nav-custom-style {
    height: auto;
    position: fixed;
    width: 100%;
  }

  .offcanvas-body {
    padding: 1rem;
  }
}

.custom-left-section-width {
  width: 100%;
}

.border-radius-12 {
  border-radius: 12px;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1052;
}

.nav-bg {
  background-color: #fff;
}

.position-fixed {
  position: fixed;
}

.pos-absolute {
  position: absolute;
}

.right-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.expanded-tabs {
  width: 95vw;
  overflow: hidden;
  margin-right: 2vw;
}
 
.bg-hide-vl{
  background-color: #ffffff;
}
.dark-theme .custom-input-text{
  color: #ffffff;
}
.dark-theme .bg-hide-vl{
  background-color: #212121;
}


.chat-text-fix {
  /* position: absolute;
  top: 1px;
  width: 60vw;
  right: 78px; */
  resize: none !important;
  min-height: 6vh !important;
}

.dark-theme .chat-text-fix {
  caret-color: white;
}

.mic-fix-text {
  position: absolute !important;
  top: 11px;
  right: 43px;
}

.send-button-text {
  position: absolute !important;
  top: 12px;
  right: -5px;
}


.right-nav {
  width: 5vw;
  height: 100vh;
  margin-right: 1vw;
}

.custom-right-nav-pill {
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* {maxWidth: activeComponent == "target" ? "100vw" : activeComponent == "codepad" ? "80vw" : "40vw"} */

.right-nav-target-max {
  max-width: 100vw !important;
}

.leftNav {
  position: fixed;
  right: 0 !important;
  bottom: 0px;
  z-index: 1030;
  left: 8.2% !important;
  width: 86% !important;
}

.right-nav-target-min {
  max-width: 80vw !important;
}

.right-nav-codepad-max {
  max-width: "80vw";
}

.right-nav-codepad-min {
  max-width: "40vw";
}

.custom-tab-content-section {
  padding-top: 12px;
  padding-bottom: 12px;
  /* margin-left: 65%; planner*/
}

.custom-tab-content-section .custom-right-side-bar-section-contents {
  background: #272727;
  height: 96vh;
  overflow: hidden;
  border-radius: 8px;
}

.custom-tab-content-section .custom-right-side-bar-section-contents .custom-right-side-bar-section-title {
  padding: 21px 24px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1490196078);
}

.custom-tab-content-section .custom-right-side-bar-section-contents .custom-right-side-bar-section-title h2 {
  color: #FFFFFF;
  margin-bottom: 0px;
}

.custom-tab-content-section .custom-right-side-bar-section-contents .custom-right-side-bar-section-title button.custom-edit-btn {
  background: transparent;
  border: none;
  display: flex;
}

.custom-tab-content-section .custom-right-side-bar-section-contents .custom-right-side-bar-section-details {
  height: 92%;
  overflow-y: auto;
}

.custom-tab-content-section .custom-right-side-bar-section-contents .custom-right-side-bar-section-details::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: var(--bg-color, #212121);
}

.custom-tab-content-section .custom-right-side-bar-section-contents .custom-right-side-bar-section-details::-webkit-scrollbar-thumb {
  background-color: var(--custom-scroll-color, #444444);
  border-radius: 5px;
}

.custom-tab-content-section .custom-right-side-bar-section-contents .custom-right-side-bar-section-details img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.custom-tab-content-section .chat-box-terminal-container {
  padding: 20px 25px;
}

.custom-tab-content-section .chat-box-terminal-container p,
.custom-tab-content-section .chat-box-terminal-container span,
.custom-tab-content-section .chat-box-terminal-container h1,
.custom-tab-content-section .chat-box-terminal-container h2,
.custom-tab-content-section .chat-box-terminal-container h3,
.custom-tab-content-section .chat-box-terminal-container h4,
.custom-tab-content-section .chat-box-terminal-container h4,
.custom-tab-content-section .chat-box-terminal-container h6,
.custom-tab-content-section .chat-box-terminal-container li {
  color: #FFFFFF;
}

.custom-tab-content-section .chat-box-planner-container {
  padding: 20px 25px;
}

.custom-tab-content-section .chat-box-planner-container p,
.custom-tab-content-section .chat-box-planner-container span,
.custom-tab-content-section .chat-box-planner-container h1,
.custom-tab-content-section .chat-box-planner-container h2,
.custom-tab-content-section .chat-box-planner-container h3,
.custom-tab-content-section .chat-box-planner-container h4,
.custom-tab-content-section .chat-box-planner-container h4,
.custom-tab-content-section .chat-box-planner-container h6,
.custom-tab-content-section .chat-box-planner-container li {
  color: #FFFFFF;
}


.toggle-right-panel .custom-tab-content-section {
  display: block;
  flex: 1;
}

.right-container {
  margin-left: 65%;
  margin-right: 4%;
}

.right-container.toggle-right-panel,
.layout-container :has(+ .toggle-right-panel) {
  flex: 0.5;
}

.custom-options-container {
  background-color: white;
  color: #212121;
  top: -85px;
  left: 70px;
  width: 130px;
  border-radius: 11px;
}

.custom-options-container li:hover , .logo-dropdown:hover{
  background-color: var(--bg-color-6, #a3a2a2);
}
.logo-dropdown:hover{
color: #FFFFFF !important;
filter: white;
}

.custom-options-container li button {
  padding: 8px 20px;
}

.dark-theme .custom-options-container {
  background-color: #272727;
  color: white;
}

.dark-theme .custom-options-container li:hover {
  background-color: var(--bg-color-6, #332929);
}

.doc-attach-container {
  border: 1px solid #EDEDED;
  border-radius: 11px;
  background: #ffffff;
}

.dark-theme .doc-attach-container {
  background: transparent;
  border: 1px solid var(--border-color, #3E3E3E);
}

.textarea-container {
  position: relative;
}

.highlighted-text {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  color: transparent;
  background: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.highlight {
  color: blue;
}

.Loader {
  display: block;
  position: fixed;
  z-index: 9999999;
  top: 50%;
  right: 50%;
}

.inner-table {
  padding: 15px 20px;
  position: relative;
}

.expanded-content {
  position: relative;
  padding-left: 20px;
}

.expanded-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3px;
  background: linear-gradient(to bottom, rgb(95, 136, 232), rgb(139, 90, 216));
}

.rotate-90 {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.open-collapse-position {
  transition: transform 0.3s ease;
}

.prompt-template {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-dot-red {
  background-color: rgb(210, 72, 72);
}

.status-dot-green {
  background-color: rgb(100, 190, 103);
}

.status-dot-yellow {
  background-color: rgb(232, 183, 114);
}

.status-dot-gray {
  background-color: gray;
}

:root {
  --soft-violet: #9370DB;
  /* Softer violet color */
  --card-border-radius: 8px;
  /* Consistent border radius */
}

.custom-card-bg {
  background-color: #ffffff;
  /* Light mode background */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  /* Ensures content doesn't overflow rounded corners */
}

.custom-card-shadow {
  box-shadow: 0 4px 10px rgba(147, 112, 219, 0.2);
  /* Softer violet shadow */
}

.custom-card-bg:hover {
  box-shadow: 0 6px 15px rgba(147, 112, 219, 0.4);
  /* Stronger soft violet shadow on hover */
  cursor: pointer;
}

.card-hover-effect {
  transition: all 0.3s ease;
}

.card-hover-effect:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 10px 20px rgba(147, 112, 219, 0.3), 0 4px 8px rgba(147, 112, 219, 0.2);
}

/* Dark mode styles */
.dark-theme .custom-card-bg {
  background-color: #3e3c3c;
  /* Dark grey background for dark mode */
}

.dark-theme .custom-card-shadow {
  box-shadow: 0 4px 10px rgba(147, 112, 219, 0.3);
  /* Slightly stronger shadow for visibility in dark mode */
}

.dark-theme .custom-card-bg:hover,
.dark-theme .card-hover-effect:hover {
  box-shadow: 0 10px 20px rgba(147, 112, 219, 0.4), 0 4px 8px rgba(147, 112, 219, 0.3);
}

.workflow-containers {
  max-width: 400px;
  /* Adjust this value as needed */
  margin: 0 auto;
  border-radius: var(--card-border-radius);
  /* Consistent rounded corners */
}

.workflow-pic {
  height: 215px;
  object-fit: cover;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
}

.toastr-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: auto;
  max-width: 90%;
  display: flex;
  justify-content: center;
}

.toastr {
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  max-width: 100%;
  background-color: #FFFFFF;
  color: #333333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toastr-content {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  min-width: 0;
}

.toastr-icon {
  margin-right: 12px;
  flex-shrink: 0;
}

.toastr-message {
  margin: 0;
  flex-grow: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  min-width: 0;
}

.btn-close.close-icon-filter {
  padding: 0.25rem;
  margin-left: 12px;
  opacity: 0.7;
  transition: opacity 0.2s;
  flex-shrink: 0;
  align-self: flex-start;
}

.btn-close.close-icon-filter:hover {
  opacity: 1;
}

.toastr.success {
  border: 1px solid #4CAF50;
}

.toastr.failure {
  border: 1px solid #F44336;
}

/* Dark theme styles */
.dark-theme .toastr {
  background-color: #3e3c3c;
  color: #FFFFFF;
}

.dark-theme .btn-close.close-icon-filter {
  filter: invert(1) grayscale(100%) brightness(200%);
}


.custom-settings {

  position: relative;
  display: inline-flex;
  vertical-align: middle;
  float: right;
  margin-top: -3%;
}

.model-option-container {
  position: relative;
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;
}

.model-option-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.model-option-tooltip {
  visibility: hidden;
  position: fixed;
  background: linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  max-width: 250px;
  word-wrap: break-word;
  z-index: 9999;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.model-option-container:hover .model-option-tooltip {
  visibility: visible;
}

[title] {
  position: relative;
  cursor: help;
}

/* [title]:hover::before {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background: linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  width: 50%;
} */

/* [title]:hover::after {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px 5px 0 5px;
  border-style: solid;
  border-color: #865BE7 transparent transparent transparent;
  width:50%
} */

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  position: fixed;
  background: linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  max-width: 250px;
  word-wrap: break-word;
  z-index: 9999;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}


.custom-techstack-selection{
  background-color: white;
  color: black;
}

.dark-theme .custom-techstack-selection{
  background-color: #333;
  color: white;
}

/* Adjust the dropdown option padding to accommodate the tooltip */
.select__option {
  padding-right: 24px !important;
  /* Adjust as needed */
}

/* Dark theme adjustments */
.dark-theme .select__control {
  background-color: #333;
  border-color: #555;
}


.dark-theme .select__menu {
  background-color: #333;
}

.dark-theme .select__option {
  background-color: #333;
  color: #fff;
}

.dark-theme .select__option--is-focused {
  background-color: #444;
}

 .select__single-value {
  color:#5A5A5A !important
}



.folder-struct-code-space {
  width: 80%;
}






.codespace-file-list {
  padding: 17px 25px;
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  height: 100vh;
  overflow-y: auto;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black
}

.dark-theme .codespace-file-list {
  background-color: #272727;
  color: white;
}


.codespace-file-list-title,
.codespace-file-contents {
  color: black;
}

.dark-theme .codespace-file-list-title {
  color: white;
}

.dark-theme-helper-header {
  background-color: white;
  color: black;
}

.dark-theme .dark-theme-helper-header {
  background-color: #272727;
  columns: white;
}

.scroll-fix-header {
  height: 25vh;
  overflow: scroll;
  overflow-x: hidden;
  background-color: white !important;
}

.dark-theme .scroll-fix-header {
  background-color: #272727 !important;
}

.codespace-file-contents {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  height: 103vh;
  overflow-y: auto;
  color: black;
  width: 100vw;
}


.dark-theme .codespace-file-contents {
  background-color: #272727;
  color: white;
}

.position-absolute-right-nav {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1030;
}

.dark-theme .card-body {
  background-color: #212121;
}

.manage-issue-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}



.md-tool-container {
  width: 80vw;
}


/* folder structure css ends  */

.chat-option-edit {
  position: absolute;
  left: -3vw;
  bottom: 135vh;
  width: fit-content;
  display: none;
  display: unset;
  background-color: btn-hover;
  border-radius: 4px;
  display: unset;
  background-color: btn-hover;
  border-radius: 4px;
}


.dummy-baba {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 25vw;
}

.monaco-editor-container {
  min-width: 630px;
  min-height: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.monaco-editor-container.vs-dark {
  border-color: rgba(255, 255, 255, 0.1);
  /* Light white border in dark mode */
  box-shadow: 0 0 20px rgba(138, 43, 226, 0.4);
  /* Violet gradient shadow */
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-bottom: 1px solid #ccc;
  /* Grey line in light mode */
}

.vs-dark .toolbar {
  background-color: #1e1e1e;
  color: #ffffff;
  border-bottom: 1px solid #444;
  /* Darker grey line in dark mode */
}

.language {
  font-family: monospace;
  font-size: 14px;
}

.buttons {
  display: flex;
  gap: 8px;
}

.buttons button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
  color: #333;
}

.vs-dark .buttons button {
  color: #ffffff;
}

.buttons button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.vs-dark .buttons button:hover,.dark-theme .logo-dropdown:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Ensure the Monaco Editor takes full width */
.monaco-editor {
  width: 100% !important;
}

.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scroll-container::-webkit-scrollbar {
  height: 8px;
}

.horizontal-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.horizontal-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.doc-attach-container {
  display: inline-flex;
  vertical-align: top;
  white-space: normal;
  min-width: 25%;
  max-width: 300px;
  /* Adjust as needed */
}

.file-name {
  display: flex;
  align-items: center;
  max-width: calc(100% - 24px);
  /* Adjust based on the width of your close button */
  overflow: hidden;
}

.file-name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.doc-attach-container {
  background-color: #f8f9fa;
  border-radius: 4px;
  overflow: hidden;
}

.file-name {
  display: flex;
  align-items: center;
  max-width: calc(100% - 60px);
  /* Adjust based on the width of your buttons */
  overflow: hidden;
}

.file-name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .file-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .file-grid-container {
    grid-template-columns: 1fr;
  }
}



/* text-area fixes starts */

.highlighted-textarea {
  position: relative;
  width: 100%;
}

.highlighted-textarea .backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  /* Ensure white spaces are respected */
  padding: 8px;
  /* Adjust padding to match the padding of the textarea */
  box-sizing: border-box;
  pointer-events: none;
  /* Make sure the backdrop text is not selectable */
  overflow: hidden;
  z-index: 1;
  color: black;
  /* Set the text color in the backdrop */
  font-family: inherit;
  /* Ensure font family matches the textarea */
  font-size: inherit;
  /* Ensure font size matches the textarea */
  line-height: 1.5;
  /* Adjust line height if needed */
  color: transparent;
  overflow-wrap: break-word;

}

.highlighted-textarea .highlight {
  color: rgb(184, 247, 12);
}

.chat-form-list-fix {
  background-color: white;
  color: black;
  list-style: none;
}

.past-convo:hover {
  background-color: blue;
}

.past-convo-view-all {
  justify-content: center;
  margin-left: 16%;
}

.dark-theme .chat-form-list-fix {
  background-color: #212121;
  color: white;
}


.bg-transparent-chat-form {
  background-color: transparent !important;
}

.bg-transparent-chat-form :hover {
  background-color: transparent;
}

.highlighted-textarea textarea {
  background: transparent;
  color: transparent;
  caret-color: black;
  /* Keeps the caret visible */
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  resize: none;
  /* Prevent resizing of the textarea */
  padding: 8px;
  /* Match padding of the backdrop */
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  /* Match border styling if any */
}

.highlighted-textarea textarea::placeholder {
  color: lightgray;
}

/* text-area fixes ends */

.second-component-hub-nav-close {
  margin-left: 8%;
  width: 90%;
}



.stop-generating-nav-close {

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 100px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%)
}

.stop-generating {

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 100px;
  left: 60%;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%)
}

.chat-option-regenrate {
  /* position: absolute;
  display: none;
  top: 14vh;
  right: -4vw;
  display: unset;
  background-color: btn-hover;
  border-radius: 4px; */

  position: absolute;
  display: block;
  top: 14px;
  text-align: end;
  right: -70px;
  display: unset;
  background-color: transparent;
  border-radius: 4px;
}

.active2 {
  display: block;
  /* Show the overlay when active */
  pointer-events: auto;
  /* Enable pointer events */
  background: linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%) !important;
  border-radius: 0.25rem;
  color: #ffffff;
}


.nav-link:hover img,.dark-theme .download-btn-icon{
  filter: brightness(0) invert(1)
}
.download-btn-icon{
  filter:invert(1)
}

.accordion-header.past-convo-button:hover .past-convo-button {
  color: white !important;
}

/* Optional: Ensure button inherits the hover effect */
.accordion-header.past-convo-button:hover .accordion-button {
  color: white !important;
}

.btn-gradient-outline:before {
  content: "";
  background: transparent;
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 0px;
  margin: 0px;
  border-radius: 4px;
  left: 0px;
  right: 0px;
  z-index: -1;
  display: block;
}

.btn-gradient-outline:hover:before {
  width: 100%;
  height: 100%;
  background: linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%) !important;


}

/* .btn-gradient-outline:focus {
  box-shadow: none;
} */

.btn-gradient-outline:hover, .btn-gradient-outline:focus {
  background: white;
}

.btn-gradient-outline:focus::before {
  width: 100%;
  height: 100%;
  background: linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%) !important;
}


/* uxd issues */

.image-size-fix {
  width: 16px;
}


.chat-content {
  margin-bottom: 2.5rem;
}

/* .chat-box {
  margin-top: 5vh;
} */

.chat-configure-position {
  position: fixed;
  top: 1;
  right: 2vw;
  z-index: 999;
}

.dropdown-menu-manage-issues {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 5rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.sys-message-fix {
  height: 40px;
  width: auto;
  margin-top: 4%;
  outline: none !important;
}

.system-message-fix button{
  outline: none !important;
}

.sys-message-fix:focus , .sys-message-fix:active {
  box-shadow: none;
}

@keyframes heartbeating {
  0%, 100% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(1.1) rotate(45deg);
  }
  50% {
    transform: scale(1.2) rotate(90deg);
  }
  75% {
    transform: scale(1.1) rotate(135deg);
  }
}
 
.system-message-rot001{
  animation: heartbeating 1s infinite;
  /* Adjust the timing to make the heartbeat more natural */
  animation-timing-function: ease-in-out;
}

.kb-bg-clr{
  color: black;
}

.dark-theme .kb-bg-clr {
  color: white;
}


.drop-down-fix-mp , .drop-down-fix-mp-li{
  background-color: white;
  color: black;
}

.dark-theme .drop-down-fix-mp ,.dark-theme .drop-down-fix-mp-li{
  background-color: black;
  color: white;
}


.drop-down-fix-mp-li:hover{
    background-color: grey;
}

.dark-theme .drop-down-fix-mp-li:hover{
    background-color: grey;
    color: white;

}

.form-check-label{
  margin-left: 1vw ;
}


.highlighted-textarea {
  position: relative;
}

/* .highlighted-textarea .backdrop {
  color: transparent;
  overflow-wrap: break-word;
  white-space: pre-wrap;
} */

.highlighted-textarea .form-control {
  margin: 0;
  outline: none;
}


/* text-area fixes ends */

/* Loader */
.custom-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  flex-direction: column;
}
.custom-loader-container img {
  width: 30px;
}

.file-attach-container{
  background-color: #f8f9fa;
  border-radius: 4px;
  overflow: hidden;
  margin: 4px;
  min-width:48%
}

.dark-theme .file-attach-container{
  background: transparent;
  border: 1px solid var(--border-color, #3E3E3E);
}

.text-end {
  text-align: right;
}

.link-click{
  cursor: pointer;
}

.text-end-manage-grid {
  text-align: right !important;
  padding-right: 15% !important;
}

.dark-theme .asami-support{
filter: "brightness(0) invert(1)";margin-left:10px;width: 28px;height: 46px
}
.dark-theme .past-convo-image{
  filter: "brightness(0) invert(1) !important"
}
.past-convo-image{
   filter: "brightness(0) invert(1) !important"
}

.asami-support{width: 28px;height: 46px
  }
  .usage-cost-dropdown{
    margin-left: -30%;
    width : 120%
  }

  .usage-cost-dropdown-asami-usage{
    margin-left: -5%;
    min-width: 175px ;
    max-width: 175px;
  }
   
   
  .loader-css{
    margin-left: 57%;
    margin-top: 23%;
  }
   
   
  .loader-css-nav-close{
    margin-left: 50%;
    margin-top: 23%;
  }



 
.left-nav-scroll {
  overflow-y: scroll;
}
 
.left-nav-scroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
 
 
.left-nav-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
 
 
.accordion-body{
  padding: 0px 1.25rem !important;
}
 
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(180deg) !important;
}
 
.dark-theme .accordion-button::after {
  content: '';
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(0deg) !important; /* No rotation by default */
}

.accordion-body::after .hide-image{
  display: none;
}

.dark-theme .accordion-body::after .hide-image{
  display: none;
}

.dark-theme .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(180deg) !important;
}
 
.chat-component-main-container{
  display: flex;
  flex-direction: row;
}
 
.dark-theme .time-colour-vl{
  color: white;
}

.dark-theme .error{
  color: white;
}


.dark-theme .time-theme{
  color: white;
}


.toggle-icon {
  position: fixed; /* or absolute */
  top: 9.5%;
  left: 19%;
  z-index: 9999; /* Ensure it's above other elements */
  height: auto;
}
.toggle-icon-closed {
  position: fixed; /* or absolute */
  top: 9.5%;
  left: 4.2%;
  z-index: 9999; /* Ensure it's above other elements */
  height: auto;
}
.toggle-icon:focus , .toggle-icon:active, .toggle-icon-closed:focus,.toggle-icon-closed:active {
  outline: none; /* Remove the outline */
  box-shadow: none; /* Remove any box shadow */
  background: none;
  background-color: transparent;
}
.dropdownwhenaddoptions{
  display: block;
  margin-bottom: 0 !important;
  padding-bottom: 10px;
}

.dark-theme .modal-content,.dark-theme .techstackbackground{
  background-color: #212121;
  color: white;
}


.dark-theme .btn-close , .dark-theme .modal-body,.dark-theme .techstackbackgroundfont{
  color: white;
}


.dark-theme .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

button.font-regular.font-13 {
  display: block; /* Makes the button take up the full width of its parent */
  margin: 20px auto; /* Centers the button horizontally */
  background-color: transparent; /* No background color, so it inherits the parent background color */
  color: blue; /* Blue text */
  border: none; /* No border */
  text-align: center; /* Centers the text inside the button */
  cursor: pointer; /* Pointer cursor on hover */
  padding: 10px 20px; /* Padding for better clickable area */
  transition: color 0.3s, background-color 0.3s; /* Smooth transition for color changes */
}

button.font-regular.font-13:hover {
  color: darkblue; /* Slightly darker blue on hover */
  background-color: rgba(0, 0, 0, 0.1); /* Light grey background on hover for better visibility */
}
.theme-table tbody td {
  white-space: pre-wrap !important;
  max-width: 50vw;
}
 
.theme-table tbody tr{
  overflow-wrap: anywhere;

}
 
.outgoing-content{
  overflow-wrap: break-word ;
}
 
.mvh-8 {
  margin-top: 8vh;
}




/* For larger desktops */
@media (min-width: 992px) {
  .left-nav-fixed {
    width: 300px;
  }
  
  .content-with-sidebar {
    margin-left: 300px;
  }
  }

  
  .flex-grow-1{
    flex-grow: 1 !important;
  }

  .dark-theme .custom-select-placeholder{
    color:white
  }
  .bouncing-dots-loader {
    display: flex;
    justify-content: center; 
    align-items: center; 
    gap: 5px; 
    height: 50px; 
    margin-top: 6px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background: linear-gradient(135deg, #8a2be2, #ee82ee); 
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
  }
  
  
  .dot:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .dot:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0); 
    }
    40% {
      transform: scale(1); 
    }
  }

  .nav-custom-style.active .btn-gradient-outline img{
    filter: none;
  }

  .nav-custom-style.active .btn-gradient-outline {
    border-radius: 0.25rem;
  }

  .project-config-href {
    all: unset; /* Reset all styles */
    cursor: pointer; /* Add cursor pointer back */
    text-decoration: none; /* Remove underline */
    color: var(--blue-link, #4BA9FF); /* Inherit text color */
    font-size: inherit; /* Inherit font size */
    font-weight: inherit; /* Inherit font weight */
  }
  
    .project-config-href-not-configured {
    all: unset; /* Reset all styles */
    cursor: pointer; /* Add cursor pointer back */
    text-decoration: none; /* Remove underline */
    color: var(--blue-link, #4BA9FF); /* Inherit text color */
    font-size: inherit; /* Inherit font size */
    font-weight: inherit; /* Inherit font weight */
  }
  
  
  .left-nav-top-fix{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .accordion-button:active , .accordion-button:focus{
    box-shadow: none;
  }
  
  
   
  .accordion-button{
    padding: 1rem 0.75rem !important;
  }

 .width-20{
  width: 20px !important;
 }

 .custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
}

.dropdown-list-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-list-item:hover {
  background-color: #f0f0f0;
}


.markdown-text-viewer ol li p{
  margin-top: -2.75vh;
  margin-left : 1.75vw;
  margin-bottom: 0vh;
}



.custom-monaco-style{
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.accordion-button:not(.collapsed)::after {
  content: '';
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(180deg) !important;
}

.dark-theme .accordion-button::after {
  content: '';
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button:hover::after {
  filter: brightness(0) invert(1) !important;
}

.file-container-code img {
  pointer-events: none;
}
 
 .small-icon {
  width: 20px;
  height: 15px;
}
 
.file-container-code {
  display: flex;
  align-items: center;
}
 

/* TypingDotsLoader.css */
/* .typing-loader-container {
  background-color: #f3f4f6;
  padding: 12px;
  border-radius: 8px;
  max-width: 250px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
 
.typing-text {
  color: #8b5cf6;
  font-weight: 500;
}
 
.typing-dots {
  display: flex;
  gap: 4px;
}
 
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
 
.dot1 {
  background-color: #8b5cf6;
  animation: bounce 1s infinite;
  animation-delay: 0s;
}
 
.dot2 {
  background-color: #9333ea;
  animation: bounce 1s infinite;
  animation-delay: 0.1s;
}
 
.dot3 {
  background-color: #a855f7;
  animation: bounce 1s infinite;
  animation-delay: 0.2s;
}
 
@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}
  */


  .shimmer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
  }
  
  
   
  .card-shimmer {
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  
  .dark-theme .card-shimmer{
    background-color: #212121;
    
  }
  
  /* Shimmer content placeholders */
  .shimmer-title {
    height: 24px;
    margin: 20px;
    border-radius: 4px;
    background-color: rgba(224, 224, 224, 0.522);
  }
  
  .dark-theme .shimmer-title{
    background-color:  #272727;
    
  }
  .shimmer-image {
    height: 200px;
    margin: 0 20px 20px;
    border-radius: 4px;
    background-color: rgba(224, 224, 224, 0.522);
  }
  
  .dark-theme .shimmer-image{
    background-color:  #272727;
    
  }
  
  .shimmer-text {
    height: 16px;
    margin: 10px 20px;
    border-radius: 4px;
    background-color: rgba(224, 224, 224, 0.522);
  }
  
  .dark-theme .shimmer-text{
    background-color:  #272727;
    
  }
  
  /* Shimmer animation */
  .shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: shimmer 2s infinite;
  }
  
  .shimmer {
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0) 100%
    );
    
    transform: skewX(-20deg);
  }
  
  .dark-theme .shimmer{
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgb(43, 43, 43) 50%,
        rgba(255, 255, 255, 0) 100%
    );
  }
  
  @keyframes shimmer {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(-60%);
    }
    100% {
        transform: translateX(150%);
    }
  }
  
  .browser-theme-handler{
    background-color: white !important;
    color: black  !important;
  }
  
  .browser-theme-handler div.scroller {
    scrollbar-color: pink lightblue;
  }
  
  .dark-theme .browser-theme-handler{
    background-color: #272727  !important;
    color: white !important;
  }
  
  
  .browser-theme-handler h2{
    color: black !important;
  }
  
  .dark-theme .browser-theme-handler h2{
    color: white !important;
  }
  
  
  .browser-height{
    min-height: 100% !important;
    overflow-y: hidden auto ;
  }


   
.markdown-text-viewer ul li p {
  margin-top: -2.5vh;
}
 
.markdown-text-viewer ol li ul li ul li {
  margin-top: 2vh;
}
 
.markdown-text-viewer ol li ol li ul{
  margin-top:1vh ;
}


.dark-theme .select__input-container input {
  color: #ffffff !important; 
}

.dark-theme .select__input-container {
  color: #808080 !important; 
}



body ::placeholder{
  color:  #6F6F6F !important;
}
.dark-theme .select__single-value {
  color: #F1F1F1 !important;
}

.select__placeholder{
  color:  #6F6F6F !important;
}
.css-1f98lf6{
  color:  #6F6F6F !important;
}

/* Backdrop styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 500; /* Ensure it's on top of other elements */
  display: none; /* Hidden by default */
}

/* Show the backdrop */
.backdrop.show {
  display: block;
}

/* Disable scrolling on the body */
.no-scroll-body {
  overflow: hidden !important
}

.example-popup{
  z-index: 700;
}

.notification-bar {
  position: fixed;
  top: 20px;
  left: 60%;
  transform: translateX(-50%);
  background-color: white;
  color: #333;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  font-family: Arial, sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: auto;
}
 
.markdown-text-viewer h3{
  font-size: 24px;
}

.markdown-text-viewer h1{
  font-size: 26px;
}



.markdown-viewer-new{
  margin-bottom: 1vh;
}

.markdown-viewer-new h3{
  font-size: 22px;
}

.markdown-viewer-new h1{
  font-size: 1.5rem;
}

.markdown-viewer-new h2{
  font-size: 1.2rem;
}

.markdown-viewer-new h2, .h2{
  font-size: 1.2rem;
}

.markdown-viewer-new h1, .h1{
  font-size: 1.5rem;
}


.highlighted-textarea {
  transition: height 0.3s ease;
}

.highlighted-textarea.grow-height {
  height: 131%;
  margin-top: -2%;
}

.planner-view-container p:nth-of-type(3) {
  border-bottom: 1px solid #cccccc; /* Light gray line */
  padding-bottom: 10px; /* Optional: Add some space below the line */
}

.color-green-dot{
  width: 8px;
  margin-left: 4px;
  height: 8px;
  background-color: #00FF00; /* Bright green */
  border-radius: 50%;
  display: inline-block;
}

img.clock-icon {
  filter: grayscale(100%);
}
.nav-footer-bg{
  background-color: white;
}
 
.dark-theme .nav-footer-bg{
  background-color: #272727;
}
#sidebar.active .nav-footer-bg {
  background-color: transparent !important;
}

.core-logic-container {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
}
 
 


